import * as React from "react"
import Layout from "../../../components/layout"
import PartnersPage from "../../../components/pageComponents/partners/partners"
import messages from "../../../i18n/lv"

const IndexPageDe = ({ location }) => (
  <Layout location={location} messages={messages}>
    <PartnersPage langKey="lv" partner="khvs" />
  </Layout>
)

export default IndexPageDe
